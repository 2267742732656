import React from 'react'
import styled from 'styled-components'

const ExplanationText = styled.div`
  width: 100%;
  color: #116f31;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.03em;
  margin-top: 16px;
`

type ExplanationProps = {
  children: React.ReactNode
}

export const Explanation: React.FC<ExplanationProps> = ({ children }) => {
  return <ExplanationText>{children}</ExplanationText>
}

export default Explanation
