import React, { useContext } from 'react'
import styled from 'styled-components'

import QuestionMark from 'components/vectors/QuestionMark'
import { InstructionsProviderContext } from 'components/providers/InstructionsProvider'

const TermToggleContainer = styled.span`
  overflow: visible;
  cursor: pointer;

  svg {
    overflow: visible;
    margin-left: 4px;
    margin-right: 4px;

    path {
      transform-origin: center;
      transform: scale(1.3) translateY(2px);
    }
  }
`

const Term = styled.span`
  margin-right: 4px;
  font-weight: bold;
`

type TermToggleProps = {
  target?: string
  children: string
}

export const TermToggle: React.FC<TermToggleProps> = ({ target, children }) => {
  const { onToggle } = useContext(InstructionsProviderContext)

  const handleToggle = () => onToggle(target || children)

  return (
    <TermToggleContainer onClick={handleToggle}>
      <Term>{children}</Term>
      <QuestionMark />
    </TermToggleContainer>
  )
}

export default TermToggle
