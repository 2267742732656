import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Check: React.FC<IconStyle> = ({
  width = '11',
  height = '10',
  stroke = '#116F31',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 6L4 9L10 1'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Check
