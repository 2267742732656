import React from 'react'
import styled from 'styled-components'

import colors from 'lib/utils/colors'
import Explanation from './Explanation'

const QuestionRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 26px;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-weight: 600;
`
const NumberContainer = styled.div`
  font-size: 21px;
  line-height: 26px;
  color: ${colors.Brass};
  margin-right: 16px;
  font-weight: bold;
`

const QuestionContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;

  letter-spacing: 0.02em;
`
const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  overflow: hidden;
`

type QuestionProps = {
  num: number
  value: string
  children: React.ReactElement
}

export const Question: React.FC<QuestionProps> = ({ num, value, children }) => {
  const [userAnswer, setUserAnswer] = React.useState<string | null>(null)

  const handleRevealAnswer = React.useCallback((userAnswer: string) => {
    setUserAnswer(userAnswer)
  }, [])

  const options = React.Children.toArray(children) as React.ReactElement[]
  const explanation = options.pop()

  return (
    <QuestionRootContainer>
      <HeaderContainer>
        <NumberContainer>{num}.</NumberContainer>
        <QuestionContainer>{value}</QuestionContainer>
      </HeaderContainer>
      <AnswersContainer>
        {options
          .map((child) =>
            React.cloneElement(child, {
              ...child.props,
              onRevealAnswer: handleRevealAnswer,
              userAnswer,
            })
          )
          .filter((child) => child.props.mdxType !== 'Explanation')}
      </AnswersContainer>
      {userAnswer && <Explanation>{explanation?.props.children}</Explanation>}
    </QuestionRootContainer>
  )
}

export default Question
