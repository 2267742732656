/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

export type InstructionsProviderContextProps = {
  openedExplanations: string[]
  onToggle: (reference: string) => void
}

export const InstructionsProviderContext = createContext<InstructionsProviderContextProps>({
  openedExplanations: [],
  onToggle: () => {},
})

export default InstructionsProviderContext.Provider
