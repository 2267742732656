import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div<{
  backgroundColor?: string
  backgroundImage?: string
  style?: CSSProperties
}>`
  display: flex;
  justify-content: center;

  ${(props) => {
    if (props.backgroundColor) {
      return css`
        background-color: ${props.backgroundColor};
      `
    }
  }}
`

export default Container
