import React, { useContext } from 'react'
import styled from 'styled-components'

import colors from 'lib/utils/colors'
import { InstructionsProviderContext } from 'components/providers/InstructionsProvider'

const CustomBlockQuote = styled.blockquote`
  padding: 12px 16px;
  margin: 0px;
  background-color: ${colors.ShadowLight};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.03em;
  margin-bottom: 24px;
`
type TermExplanationProps = {
  term: string
  children: string
}

export const TermExplanation: React.FC<TermExplanationProps> = ({ term, children }) => {
  const { openedExplanations } = useContext(InstructionsProviderContext)

  const isOpen = openedExplanations.includes(term)

  if (!isOpen) return null

  return <CustomBlockQuote>{children}</CustomBlockQuote>
}

export default TermExplanation
