import React from 'react'
import styled from 'styled-components'

import Expand from 'components/vectors/Expand'
import Collapse from 'components/vectors/Collapse'
import Container from './Container'

import colors from 'lib/utils/colors'

const AccordionItemContainer = styled(Container)`
  padding: 20px 2px;
  flex-direction: row;
  border-top: 1px solid #c9c9c9;

  :first-of-type {
    border-top: ${`1px solid ${colors.Brass}`};
  }
`

const AccordionItemContentContainer = styled(Container)`
  flex-direction: column;
`

const AccordionHeaderContainer = styled(Container)<{ isExpanded?: boolean }>`
  align-items: center;
  margin-bottom: ${(props) => (!props.isExpanded ? '0px' : '15px')};
  cursor: pointer;
`

const AccordionContentContainer = styled(Container)<{ isExpanded?: boolean }>`
  display: ${(props) => (!props.isExpanded ? 'none' : 'flex')};
  padding-right: 30px;
  flex-direction: column;
`

const AccordionHeader = styled.h5`
  color: ${colors.Brass};
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 0px;
  margin-right: 8px;
`

const AccordionItemCounter = styled(AccordionHeader)`
  margin-right: 1rem;
`

const AccordionExpandCollapseIcon = styled(Container)``

type AccordionItemProps = {
  header: string
  children: React.ReactNode
  listNumber: number
  activeItem?: number
  onClick: (listNumber: number) => void
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  listNumber,
  activeItem,
  header,
  onClick,
}) => {
  const handleOpen = React.useCallback(() => {
    onClick(listNumber)
  }, [listNumber, onClick])

  const isExpanded = activeItem === listNumber

  return (
    <AccordionItemContainer>
      <AccordionItemCounter>{listNumber}.</AccordionItemCounter>
      <AccordionItemContentContainer style={{ flex: 2 }}>
        <AccordionHeaderContainer onClick={handleOpen} isExpanded={isExpanded}>
          <AccordionHeader style={{ flex: 1 }}>{header}</AccordionHeader>
          <AccordionExpandCollapseIcon>
            {isExpanded ? <Expand /> : <Collapse />}
          </AccordionExpandCollapseIcon>
        </AccordionHeaderContainer>
        <AccordionContentContainer isExpanded={isExpanded}>{children}</AccordionContentContainer>
      </AccordionItemContentContainer>
    </AccordionItemContainer>
  )
}

export default AccordionItem
