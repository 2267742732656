import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Expand: React.FC<IconStyle> = ({
  width = '12',
  height = '7',
  stroke = '#313131',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='11.5605'
        y='1.39996'
        width='7.91959'
        height='1.9799'
        transform='rotate(135 11.5605 1.39996)'
        fill={stroke}
      />
      <rect
        x='1.63562'
        width='7.91959'
        height='1.9799'
        transform='rotate(45 1.63562 0)'
        fill={stroke}
      />
    </svg>
  )
}

export default Expand
