import React from 'react'

const QuestionMark: React.FC = () => {
  return (
    <svg width={18} height={18} viewBox={`0 0 18 18`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z'
        fill='#C9C9C9'
      />
      <path
        d='M6.42802 7.808H7.93502C7.94602 7.148 8.23202 6.829 8.77102 6.829C9.25502 6.829 9.54102 7.038 9.54102 7.5C9.54102 8.556 8.18802 8.655 8.18802 10.349H9.65102C9.65102 9.062 11.345 8.842 11.345 7.181C11.345 5.949 10.432 5.256 8.95802 5.256C7.38502 5.256 6.43902 6.191 6.42802 7.808ZM8.89202 11.185C8.34202 11.185 7.95702 11.57 7.95702 12.131C7.95702 12.692 8.34202 13.088 8.89202 13.088C9.44202 13.088 9.83802 12.692 9.83802 12.131C9.83802 11.57 9.44202 11.185 8.89202 11.185Z'
        fill='white'
      />
    </svg>
  )
}

export default QuestionMark
