import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Collapse: React.FC<IconStyle> = ({
  width = '12',
  height = '7',
  stroke = '#313131',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='7.91959'
        height='1.9799'
        transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 11.5607 5.60004)'
        fill={stroke}
      />
      <rect
        width='7.91959'
        height='1.9799'
        transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 1.63574 7)'
        fill={stroke}
      />
    </svg>
  )
}

export default Collapse
