import React, { useMemo } from 'react'
import styled from 'styled-components'

import Check from 'components/vectors/Check'

import colors from 'lib/utils/colors'

type StatusObj = {
  color: string
  bgColor: string
  content: React.ReactNode
  isError?: boolean
}

const ChoiceRootContainer = styled.div<{ bgColor: string }>`
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
`

const ChoiceItemContainer = styled.div<{ option: string; isError?: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: ${(props) => (props.option !== 'C' ? '1px solid #dcdcdc' : '')};
  padding: 16px 8px;
  color: ${(props) => (props.isError ? '#B14B44' : 'black')};
`

const Circle = styled.div<{ color: string; isError?: boolean }>`
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  text-align: center;
  font-weight: bolder;
  line-height: 28px;

  border-radius: 50%;
  border: ${(props) => `2px solid ${props.color}`};
  color: ${(props) => (props.isError ? props.color : colors.Brass)};
  margin-right: 20px;
`

type AnswerProps = {
  option: string
  correct: boolean
  userAnswer?: string
  children: string
  onRevealAnswer: (option: string) => void
}

export const Choice: React.FC<AnswerProps> = ({
  children,
  correct = false,
  option,
  onRevealAnswer,
  userAnswer,
}) => {
  const handleRevealAnswer = React.useCallback(() => {
    if (userAnswer) return
    onRevealAnswer(option)
  }, [option, onRevealAnswer, userAnswer])

  const statusObj: StatusObj = useMemo(() => {
    if (!userAnswer || (userAnswer !== option && !correct)) {
      return {
        content: option,
        bgColor: '#FFFFFF',
        color: colors.Brass,
      }
    }

    if (correct) {
      return {
        content: <Check />,
        bgColor: 'rgba(10, 134, 52, 0.13)',
        color: '#116F31',
      }
    }

    return {
      content: option,
      bgColor: '#FFFFFF',
      color: '#B14B44',
      isError: true,
    }
  }, [option, correct, userAnswer])

  return (
    <ChoiceRootContainer onClick={handleRevealAnswer} bgColor={statusObj.bgColor}>
      <ChoiceItemContainer option={option} isError={statusObj.isError}>
        <Circle color={statusObj.color} isError={statusObj.isError}>
          {statusObj.content}
        </Circle>
        {children}
      </ChoiceItemContainer>
    </ChoiceRootContainer>
  )
}

export default Choice
